import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import * as loadScript from "simple-load-script"
import "babel-polyfill"

import {
  Header,
  Preloader,
  Sidenav,
} from "../components"
import { Link } from "gatsby"

const NotFoundPage = ({
  data: {
    prismicAbout,
    prismicResume,
    prismicSidenav,
    prismicHero,
    allPrismicPost,
    prismicContact,
  },
  pageContext: { locale },
}) => {
  useEffect(() => {
    ;(async function loadScripsAsync() {
      await loadScript("/scripts/jquery.min.js", { inBody: true })
      await loadScript("/scripts/bootstrap.min.js", { inBody: true })
      await loadScript("/scripts/plugins.min.js", { inBody: true })
      await loadScript("/scripts/main-scripts.js", { inBody: true })
    })()
  }, [])

  return (
    <>
      <Helmet>
        <title>Noé Gambini - Frontend Developer</title>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Poppins:100,300,500,600,700,900&display=swap"
        />
        <link rel="stylesheet" href="/styles/bootstrap.min.css" />
        <link rel="stylesheet" href="/styles/plugins.min.css" />
        <link rel="stylesheet" href="/styles/styles.css" />
        <link rel="stylesheet" href="/styles/skin-1.css" />
      </Helmet>
      <Preloader />
      <Header />
      <Sidenav data={prismicSidenav.data} locale={locale} />
      <div id="site-wrapper">
        <section className="not-found">
        {locale === "fr-fr" ? "Erreur 404 : page non trouvée" : "Error 404 : page not found"}
          <br/>
          <Link to={
            locale === "fr-fr" ? "/" : "/en"
          } className="button button-md button-primary">
          {locale === "fr-fr" ? "Revenir à la page d'accueil" : "Get back to homepage"}
          </Link>
        </section>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query NotFoundQuery($locale: String!) {
    prismicSidenav(lang: { eq: $locale }) {
      data {
        testimonials {
          text
        }
        services {
          text
        }
        resume {
          text
        }
        home {
          text
        }
        contact {
          text
        }
        blog {
          text
        }
        about {
          text
        }
      }
    }
    prismicHero(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        jobs {
          title1 {
            text
          }
        }
        i_am_a {
          text
        }
        button_secondary {
          text
        }
        button_primary {
          text
        }
        based_in {
          text
        }
      }
    }
    prismicAbout(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        subtitle {
          text
        }
        description {
          html
        }
        skills {
          title1 {
            text
          }
          description1 {
            html
          }
          icon {
            text
          }
        }
      }
      lang
    }
    prismicResume(lang: { eq: $locale }) {
      data {
        tech {
          item {
            text
          }
        }
        experience {
          company {
            text
          }
          description {
            html
          }
          dates {
            text
          }
          icon {
            text
          }
          legend {
            text
          }
        }
        education {
          name {
            text
          }
          legend {
            text
          }
          icon {
            text
          }
          description {
            html
          }
          dates {
            text
          }
        }
        title {
          text
        }
        subtitle {
          text
        }
        title_experience {
          text
        }
        title_education {
          text
        }
      }
    }
    allPrismicPost(filter: {lang: {eq: $locale}}) {
      edges {
        node {
          data {
            date(formatString: "ll")
            title {
              text
            }
            image_header {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 300)
                }
              }
            }
          }
          uid
        }
      }
    }
    prismicContact(lang: { eq: $locale }) {
      data {
        your_name {
          text
        }
        your_message {
          text
        }
        your_email {
          text
        }
        title {
          text
        }
        subtitle {
          text
        }
        description {
          html
        }
        submit_button {
          text
        }
      }
    }
  }
`

export default NotFoundPage
